<template>
  <v-card outlined flat rounded="xl">
    <v-card-title>
      <h2 v-t="'community.companySearch.title'"></h2>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="searchText"
        append-icon="mdi-magnify"
        label="Workspace Search"
        outlined
        dense
        hide-details></v-text-field>
    </v-card-title>

    <v-data-table
      hide-default-header
      hide-default-footer
      :headers="headers"
      :items="companyList"
      :search="searchText"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      :no-data-text="$t('common.text.noData')"
      :no-results-text="$t('common.text.noMatchingResult')">
      <template v-slot:[`item.name`]="{ item }">
        <div>
          <v-menu v-model="item.menu" :close-on-content-click="false" :nudge-width="200" offset-x>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="hoverUnderline pointer">{{ item.name }}</span>
            </template>

            <company-info :cmpy="item" />
          </v-menu>
        </div>
      </template>
      <template v-slot:[`item.request`]="{ item }">
        <v-btn
          outlined
          :disabled="item.disabled"
          color="secondary"
          @click="requestJoin(item)"
          v-t="'common.button.join'"></v-btn>
      </template>
    </v-data-table>

    <div class="text-center pt-2" v-if="pageCount > 0">
      <v-pagination v-model="page" :length="pageCount" color="secondary" :total-visible="$pagePV"></v-pagination>
    </div>

    <v-card flat rounded="lg" class="pa-6 mx-4 my-6 d-flex align-center" color="gray6">
      <span class="font-gray2" v-t="'community.noCompany'"></span>
      <v-spacer />
      <v-btn color="secondary" dark :elevation="0" @click="show = true">{{ $t("common.button.newCompany") }}</v-btn>
    </v-card>

    <create-company :show.sync="show" />
  </v-card>
</template>

<script>
import codeHandler from "@/utils/codeHandler";
import CreateCompany from "@/components/community/CreateCompany.vue";
import CompanyInfo from "@/components/common/popover/CompanyInfo.vue";
import { hasValue } from "@/utils/util";

export default {
  props: ["itemsPerPage"],
  components: { CreateCompany, CompanyInfo },
  data() {
    return {
      show: false,
      searchText: "",
      page: 1,
      pageCount: 0,
      headers: [{ value: "name" }, { value: "request", cellClass: "w100" }],
      companyList: [],
    };
  },
  methods: {
    searchCompany() {
      this.$http.get("/company/list").then(res => {
        if (res.data.status === 200) {
          this.companyList = res.data.data.map(company => ({
            ...company,
            contactCall: hasValue(company.contactCallCountry)
              ? `${company.contactCallCountry} ${company.contactCall}`
              : company.contactCall,
            // disabled: false,
            showPopover: false,
          }));
        } else codeHandler(res.data);
      });
    },
    requestJoin(item) {
      this.$http.post("/user/company", { cmpyUUID: item.cmpyUUID }).then(res => {
        if (res.data.status === 200) {
          // item.disabled = true;
          this.$store.dispatch("user/getCompanyList");
          this.$toast.info(this.$t("community.companySearch.requestCompany"));
        } else {
          // if (res.data.code == "USER004" || res.data.code == "USER032")
          // item.disabled = true;
          codeHandler(res.data);
        }
      });
    },
  },
  created() {
    this.searchCompany();
  },
};
</script>

<style></style>
