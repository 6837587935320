var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":"","flat":"","rounded":"xl"}},[_c('v-card-title',[_c('h2',{directives:[{name:"t",rawName:"v-t",value:('community.companySearch.title'),expression:"'community.companySearch.title'"}]}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Workspace Search","outlined":"","dense":"","hide-details":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-data-table',{attrs:{"hide-default-header":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.companyList,"search":_vm.searchText,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"no-data-text":_vm.$t('common.text.noData'),"no-results-text":_vm.$t('common.text.noMatchingResult')},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"hoverUnderline pointer"},'span',attrs,false),on),[_vm._v(_vm._s(item.name))])]}}],null,true),model:{value:(item.menu),callback:function ($$v) {_vm.$set(item, "menu", $$v)},expression:"item.menu"}},[_c('company-info',{attrs:{"cmpy":item}})],1)],1)]}},{key:"item.request",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('common.button.join'),expression:"'common.button.join'"}],attrs:{"outlined":"","disabled":item.disabled,"color":"secondary"},on:{"click":function($event){return _vm.requestJoin(item)}}})]}}],null,true)}),(_vm.pageCount > 0)?_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"color":"secondary","total-visible":_vm.$pagePV},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e(),_c('v-card',{staticClass:"pa-6 mx-4 my-6 d-flex align-center",attrs:{"flat":"","rounded":"lg","color":"gray6"}},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('community.noCompany'),expression:"'community.noCompany'"}],staticClass:"font-gray2"}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","dark":"","elevation":0},on:{"click":function($event){_vm.show = true}}},[_vm._v(_vm._s(_vm.$t("common.button.newCompany")))])],1),_c('create-company',{attrs:{"show":_vm.show},on:{"update:show":function($event){_vm.show=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }