var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pb-4",attrs:{"outlined":"","flat":"","rounded":"xl"}},[_c('v-card-title',[_c('h2',{directives:[{name:"t",rawName:"v-t",value:('community.myCompanyList.title'),expression:"'community.myCompanyList.title'"}]})]),_c('v-data-table',{attrs:{"hide-default-header":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.companyList,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"no-data-text":_vm.$t('community.myCompanyList.noData'),"no-results-text":_vm.$t('common.text.noMatchingResult')},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.companyName",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.getStatusInfo(item.isUse).tooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.getStatusInfo(item.isUse))?_c('span',{class:("font-" + (_vm.getStatusInfo(item.isUse).color))},[_vm._v(" "+_vm._s(_vm.getStatusInfo(item.isUse).text)+" ")]):_vm._e(),_c('span',_vm._g(_vm._b({class:_vm.getStatusInfo(item.isUse).textClass},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.companyName)+" ")])]}}],null,true)},[_c('div',[_c('span',{directives:[{name:"t",rawName:"v-t",value:('community.myCompanyList.requestExpired'),expression:"'community.myCompanyList.requestExpired'"}]}),_c('span',{staticClass:"font-error"},[_vm._v(_vm._s(_vm.$m(item.sysRegDt).add(7, "d").format("MMMM Do")))])])])]}},{key:"item.contents",fn:function(ref){
var item = ref.item;
return [(_vm.isJoin(item.isUse))?_c('router-link',{attrs:{"to":("/company/" + (item.cmpyUUID))}},[_c('v-btn',{attrs:{"elevation":0,"color":"secondary"}},[_vm._v("GO")])],1):_vm._e(),(_vm.isDenied(item.isUse))?_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('common.button.reason'),expression:"'common.button.reason'"}],attrs:{"dark":"","color":"gray3","elevation":0},on:{"click":function($event){return _vm.checkContents(item.rejectReason)}}}):_vm._e()]}}],null,true)}),(_vm.pageCount > 1)?_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"color":"secondary","total-visible":_vm.$pagePV},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }