<template>
  <v-card outlined flat rounded="xl" class="pb-4">
    <v-card-title>
      <h2 v-t="'community.myCompanyList.title'"></h2>
    </v-card-title>

    <v-data-table
      hide-default-header
      hide-default-footer
      :headers="headers"
      :items="companyList"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      :no-data-text="$t('community.myCompanyList.noData')"
      :no-results-text="$t('common.text.noMatchingResult')">
      <template v-slot:[`item.companyName`]="{ item }">
        <v-tooltip bottom :disabled="!getStatusInfo(item.isUse).tooltip">
          <template v-slot:activator="{ on, attrs }">
            <span v-if="getStatusInfo(item.isUse)" :class="`font-${getStatusInfo(item.isUse).color}`">
              {{ getStatusInfo(item.isUse).text }}
            </span>
            <span :class="getStatusInfo(item.isUse).textClass" v-bind="attrs" v-on="on">
              {{ item.companyName }}
            </span>
          </template>
          <div>
            <span v-t="'community.myCompanyList.requestExpired'"></span>
            <span class="font-error">{{ $m(item.sysRegDt).add(7, "d").format("MMMM Do") }}</span>
          </div>
        </v-tooltip>
      </template>
      <template v-slot:[`item.contents`]="{ item }">
        <router-link :to="`/company/${item.cmpyUUID}`" v-if="isJoin(item.isUse)">
          <v-btn :elevation="0" color="secondary">GO</v-btn>
        </router-link>

        <v-btn
          dark
          v-if="isDenied(item.isUse)"
          color="gray3"
          :elevation="0"
          @click="checkContents(item.rejectReason)"
          v-t="'common.button.reason'"></v-btn>
      </template>
    </v-data-table>

    <div class="text-center pt-2" v-if="pageCount > 1">
      <v-pagination v-model="page" :length="pageCount" color="secondary" :total-visible="$pagePV"></v-pagination>
    </div>
  </v-card>
</template>

<script>
import { company } from "@/utils/statusHandler";
export default {
  props: ["itemsPerPage"],
  data() {
    return {
      page: 1,
      pageCount: 0,
      headers: [{ value: "companyName" }, { value: "contents", cellClass: "w100" }],
    };
  },
  computed: {
    companyList() {
      return this.$store.state.user.companyList;
    },
  },
  methods: {
    checkContents(rejectReason) {
      this.$toast.info(rejectReason);
    },
    getStatusInfo: company.requestStatusHandler,
    isDenied: company.isDenied,
    isJoin: company.isJoin,
  },
};
</script>

<style></style>
