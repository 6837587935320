<template>
  <div class="community">
    <v-row>
      <v-col cols="8">
        <div class="page-title">
          <v-icon color="secondary"> mdi-forum</v-icon>
          <p class="title-1" v-t="'community.subtitle'"></p>
        </div>

        <div class="gutter-mt-10">
          <my-company-list class="my-company-list" :itemsPerPage="companyListItemsPerPage" />
          <company-search class="company-seach" :itemsPerPage="companySearchItemsPerPage" />
        </div>
      </v-col>
      <v-col cols="4">
        <fashion-news class="news" :newsItemCount="newsItemCount" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MyCompanyList from "@/components/community/MyCompanyList.vue";
import CompanySearch from "@/components/community/CompanySearch.vue";
import FashionNews from "@/components/community/FashionNews.vue";

export default {
  data() {
    return {
      companyListItemsPerPage: 4,
      companySearchItemsPerPage: 6,
      newsItemCount: 4,
    };
  },
  components: { MyCompanyList, CompanySearch, FashionNews },
};
</script>

<style></style>
