<template>
  <v-card outlined flat rounded="xl" class="fh" color="#F3F6FB">
    <v-card-title>
      <h2>{{ $t("community.fashionNews") }}</h2>
      <v-spacer></v-spacer>
      <v-autocomplete
        dense
        solo
        hide-details
        flat
        outlined
        :items="countries"
        v-model="selectCountry"
        @change="getNewsList">
        <template v-slot:append>
          <v-icon> mdi-chevron-down </v-icon>
        </template>
      </v-autocomplete>
    </v-card-title>

    <v-text-field
      dense
      outlined
      hide-details
      class="ma-4"
      v-model="searchText"
      append-icon="mdi-magnify"
      label="Keyword Search"
      background-color="white"
      @keyup="getNewsList"></v-text-field>

    <v-card
      v-for="(item, index) in items"
      :key="index"
      tile
      :elevation="0"
      class="ma-4 article"
      color="rgba(0, 0, 0, 0)">
      <v-row class="pa-0 article-info" no-gutters>
        <v-col cols="4" class="pointer img-wrap">
          <v-img :src="item.imageUrl"></v-img>
        </v-col>

        <v-col cols="8" class="pointer text-wrap" @click="goNews(item.contentUrl)">
          <h3>{{ item.headline }}</h3>
          <p class="mt-3 p-elli">{{ item.sentence }}</p>
        </v-col>
      </v-row>

      <v-row class="ma-0 mt-1 pa-0 align-center font-secondary" no-gutters>
        <span>{{ item.outlets }}</span>
        <div class="divider" />
        <span>{{ item.reporter }}</span>
        <div class="divider" />
        <span>{{ $m(item.createDate).format("yyyy-MM-DD") }}</span>
      </v-row>

      <v-divider v-if="index !== items.length - 1" class="mt-4"></v-divider>
    </v-card>
  </v-card>
</template>

<script>
import codeHandler from "@/utils/codeHandler";

export default {
  props: ["newsItemCount"],
  data() {
    return {
      selectCountry: "en",
      countries: [
        {
          text: "English",
          value: "en",
        },
        {
          text: "Indonesian",
          value: "id",
        },
        {
          text: "Korean",
          value: "ko",
        },
        {
          text: "Vietnamese",
          value: "vi",
        },
      ],
      searchText: "",
      items: [],
    };
  },
  methods: {
    getNewsList() {
      this.$http
        .get("/news/list", {
          data: {
            country: this.selectCountry,
            searchText: this.searchText,
            cnt: this.newsItemCount,
          },
        })
        .then(res => {
          if (res.data.status === 200) this.items = res.data.data;
          else codeHandler(res.data);
        });
    },
    goNews(url) {
      url && window.open(url, "_blank");
    },
  },
  created() {
    this.getNewsList();
  },
};
</script>

<style></style>
